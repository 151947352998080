import { NextPage } from 'next';
import Head from 'next/head';

import { Page } from 'modules/common/layout';
import { NotFound } from 'modules/servicePages';

/**
 * Страница 404 ошибки (не найдено).
 */
const NotFoundPage: NextPage = () => (
  <Page>
    <Head>
      <title>Страница не найдена</title>
    </Head>

    <NotFound />
  </Page>
);

export default NotFoundPage;
